/**
 * Declare Variable
 */
const state = {
  location: null,

  locationState: null,

  locationDetail: {
    visibleItem: 'data'
  },

  defaultOpeningHours: [],

  exceptionOpeningHours: [],

  locationsPageState: {
    page: 1,
    searchTerm: "",
    channelId: 0,
    countryId: 0,
    itemsPerPage: 50,
    activationStatus: -2,
    publishStatus: -1
  },

  // accounts: [],

  // spaces: [],

  // options: [],

  // vouchers: []
};

const getters = {
  getLocation: (state) => state.location,
  getLocationState: (state) => state.locationState,
  getLocationDetail: (state) => state.locationDetail,
  getLocationsPageState: (state) => state.locationsPageState,
  getDefaultOpeningHours: state => state.defaultOpeningHours,
  getExceptionOpeningHours: state => state.exceptionOpeningHours,

  // getAccounts: state => state.accounts,

  // getSpaces: state => state.spaces,

  // getOptions: state => state.options,

  // getVouchers: state => state.vouchers
};

const actions = {};

const mutations = {
  setLocation: (state, location) => {
    state.location = location;
  },

  setLocationState: (state, locationState) => {
    state.locationState = locationState;
  },

  setLocationDetail: (state, locationDetail) => {
    state.locationDetail = locationDetail;
  },

  setLocationsPageState: (state, locationsPageState) => {
    state.locationsPageState = locationsPageState;
  },

  setDefaultOpeningHours(state, newState) {
    state.defaultOpeningHours = newState
  },

  setExceptionOpeningHours(state, newState) {
    state.exceptionOpeningHours = newState
  }
};

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
