/**
 * Declare Variable
 */
const reservationsPageState = {
  bottom: false,
  page: 1,
  itemsPerPage: 25,
  searchTerm: "",
  channelId: 0,
  locationId: 0,
  meetingtypeId: 0,
  statusId: 2,
  month: new Date().getMonth() + 1,
  reachedEnd: false,
  reservations: [],
  year: new Date().getFullYear(),
};

const state = {
  reservation: null,

  reservationsPageState: Object.create(reservationsPageState),
};

const getters = {
  getReservation: (state) => state.reservation,
  getReservationsPageState: (state) => state.reservationsPageState,
};

const mutations = {
  /**
   * Set reservation in store
   */
  setReservation: (state, newState) => {
    state.reservation = newState;
  },

  /**
   * Set reservation page state in store
   */
  setReservationsPageState: (state, newState) => {
    state.reservationsPageState = newState;
  },
};

const actions = {};

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
