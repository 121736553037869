const optionRoutes = {
  path: 'options',
  name: 'optionsOverview',
  components: {
    default: () => import('@/views/PageRoutView')
  },
  redirect: { name: 'options' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/:channelId/options',
      name: 'options',
      component: () => import('@/views/Options/Options'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/options/:optionId',
      name: 'options-detail',
      component: () => import('@/views/Options/OptionDetail'),
      meta: {
        requiresAuth: true
      }
    },


  ]
}

export default optionRoutes
