const locationRoutes = {
  path: 'locations',
  name: 'locationsOVerview',
  components: {
    default: () => import('@/views/PageRoutView')
  },
  redirect: { name: 'locations' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/:channelId/locations',
      name: 'locations',
      component: () => import('@/views/Locations/Locations'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/locations/:locationId',
      name: 'locations-detail',
      component: () => import('@/views/Locations/LocationDetail'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/locations/:locationId/options/:optionId',
      name: 'locations-options-detail',
      component: () => import('@/components/Options/OptionDetail'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/locations/:locationId/spaces/:spaceId',
      name: 'spaces-detail',
      component: () => import('@/views/Spaces/SpaceDetail'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/locations/:locationId/yield/:yieldId',
      name: 'settings-yield-detail',
      component: () => import('@/components/Yield/YieldDetail'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/location/:locationId/vouchers/:voucherId',
      name: 'location-vouchers-detail',
      component: () => import('@/views/Vouchers/VoucherDetail'),
      meta: {
        requiresAuth: true
      }
    },
  ]
}

export default locationRoutes
