import axios from "axios"
import store from "@/store"

export const newBundle = (channelId, bundle) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/bundle`,
    JSON.stringify(bundle),
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const updateBundle = (channelId, bundle) => {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/bundle`,
    JSON.stringify(bundle),
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const removeBundle = (channelId, bundle) => {
  return axios.delete(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/bundle/${bundle.Id}`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const getBundlesData = (channelId) => {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/bundles`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {

  }
}