import Vue from 'vue'

Vue.filter('getMeetingtypeName', function(value) {
  let name = ''

  switch (value) {
    case 1:
      name = 'Meeting room'
      break
    case 2:
      name = 'Workspace'
      break
    case 3:
      name = 'Office'
      break
  }

  return name
})

Vue.filter('getMeetingtypeShortName', function(value) {
  let name = ''

  switch (value) {
    case 1:
      name = 'Meeting'
      break
    case 2:
      name = 'Work'
      break
    case 3:
      name = 'Desk'
      break
  }

  return name
})

Vue.filter('getMeetingtypeAbbreviation', function(value) {
  let name = ''

  switch (value) {
    case 1:
      name = 'MS'
      break
    case 2:
      name = 'WS'
      break
    case 3:
      name = 'DS'
      break
  }

  return name
})
