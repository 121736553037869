const subscriptionRoutes = {
  path: 'subscriptions',
  name: 'subscriptionsOverview',
  components: {
    default: () => import('@/views/PageRoutView')
  },
  redirect: { name: 'subscriptions' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/:channelId/subscriptions',
      name: 'subscriptions',
      component: () => import('@/views/Subscriptions/Subscriptions'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/:channelId/subscriptions/:subscriptionId',
      name: 'subscriptions-detail',
      component: () => import('@/views/Subscriptions/SubscriptionDetail'),
      meta: {
        requiresAuth: true
      }
    },


  ]
}

export default subscriptionRoutes
