const accountRoutes = {
  path: '/account',
  name: 'my-account',
  components: {
    default: () => import('@/views/PageRoutView'),
  },
  redirect: { name: 'home' },
  children: [
    {
      path: 'dashboard',
      name: 'my-account-dashboard',
      component: () =>
        import(
          /* webpackChunkName: "my-account-dashboard" */ '@/views/Frontend/Account/Dashboard.vue'
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'login',
      name: 'my-account-login',
      component: () =>
        import(
          /* webpackChunkName: "my-account-login" */ '@/views/Frontend/Account/Login.vue'
        ),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: 'newpasswordrequest',
      name: 'my-account-forgotpassword',
      component: () =>
        import(
          /* webpackChunkName: "m-a-forgotpw" */ '@/views/Frontend/Account/ForgotPassword.vue'
        ),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: 'setpassword',
      name: 'my-account-setpassword',
      component: () =>
        import(
          /* webpackChunkName: "m-a-setpw" */ '@/views/Frontend/Account/SetPassword.vue'
        ),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: 'register',
      name: 'my-account-register',
      component: () =>
        import(
          /* webpackChunkName: "my-account-register" */ '@/views/Frontend/Account/Register.vue'
        ),
      meta: {
        requiresAuth: false,
      },
    },
  ],
}

export default accountRoutes
