export const state = {}
export const getters = {}
export const actions = {}
export const mutations = {}

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { eraseCookie, setCookie } from '@/utils/browserStorage'

import accountStore from '@/store/modules/account'
import bundleStore from '@/store/modules/bundle'
import cartStore from '@/store/modules/cart'
import channelStore from '@/store/channel'
import feeinvoiceStore from '@/store/modules/feeinvoice'
import functionalityStore from '@/store/modules/functionality'
import locationStore from '@/store/location'
import menuStore from '@/store/modules/menu'
import optionStore from '@/store/option'
import packageStore from '@/store/modules/package'
import profileStore from '@/store/modules/profile'
import reservationStore from '@/store/reservation'
import spaceStore from '@/store/space'
import subscriptionStore from '@/store/modules/subscription'
import tagStore from '@/store/modules/tag'
import termStore from '@/store/modules/term'
import voucherStore from '@/store/voucher'
import yieldStore from '@/store/yield'
import versionControl from '@/store/versionControl'
import widgetStore from '@/store/modules/widget'

// Import the plugin module here
import localStoragePlugin from './plugins/localStorage'

Vue.use(Vuex)

export default new Vuex.Store({
  // Next, register the plugin using the `plugins` property on the store
  plugins: [localStoragePlugin],

  state: {
    appStartupLoadStatus: {
      verifyProfileToken: false,
      profileData: false,
    },

    appStartupLoadReady: false,

    cookieExpireAfterNrDays: 100,
    isInitiated: false,
  },

  getters: {
    /**
     * Get app startup load status
     */
    getAppStartupLoadStatus: (state) => state.appStartupLoadStatus,

    /**
     * Get app startup load ready state
     */
    getAppStartupLoadReady: (state) => state.appStartupLoadReady,

    getIsInitiated: (state) => {
      return state.isInitiated
    },
  },

  mutations: {
    // Load our state from the store
    initializeStore() {
      // const data = localStorage.getItem('ccmState');
      // ////console.log(data)
      // if (data) {
      //   this.replaceState(Object.assign(this.state, JSON.parse(data)));
      // }
    },

    /**
     * Set new app startup load state
     */
    setAppStartupLoadStatus: (state, newState) => {
      state.appStartupLoadStatus = newState
    },

    /**
     * Set app startup load ready state
     */
    setAppStartupLoadReady: (state, newState) => {
      state.appStartupLoadReady = newState
    },

    setIsInitiated: (state, newIsInitiated) => {
      state.isInitiated = newIsInitiated
    },
  },

  actions: {
    /**
     * Update app startup status
     * When all needed data for startup is loaded update state appStartupLoadReady
     * @param {*} context
     */
    updateAppStartupLoadStatus(context, loadType = '') {
      let appStartupLoadStatus = context.state.appStartupLoadStatus
      appStartupLoadStatus[loadType] = true
      context.commit('setAppStartupLoadStatus', appStartupLoadStatus)

      let notReady = false
      for (let key in appStartupLoadStatus) {
        if (appStartupLoadStatus[key] === false) {
          notReady = true
          break
        }
      }

      context.commit('setIsInitiated', !notReady)

      context.commit('setAppStartupLoadReady', !notReady)
    },

    hasChannelAccess(context, channelId) {
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/api/channel/${channelId}/hasaccess`,
          {
            headers: {
              'Content-type': 'application/json',
              apiToken: process.env.VUE_APP_API_KEY,
              profileToken: context.getters.getProfileToken,
            },
          }
        )
        .then((response) => {
          context.commit('setHasChannelAccess', response.data)
          context.dispatch('getChannelData', channelId)
        })
    },

    resetTendersPageState: (context) => {
      let channel = context.getters.getChannel

      let tendersPageState = {
        bottom: false,
        page: 1,
        itemsPerPage: 25,
        searchTerm: '',
        channelId: channel !== null ? channel.Id : 0,
        reachedEnd: false,
        tenders: [],
      }

      context.commit('setTendersPageState', tendersPageState)
    },
  },

  modules: {
    accountStore,
    bundleStore,
    cartStore,
    channelStore,
    feeinvoiceStore,
    functionalityStore,
    locationStore,
    menuStore,
    optionStore,
    packageStore,
    profileStore,
    reservationStore,
    spaceStore,
    subscriptionStore,
    tagStore,
    termStore,
    versionControl,
    voucherStore,
    widgetStore,
    yieldStore,
  },
})
