import Vue from 'vue'

Vue.filter(
  'stringShortner',
  (str = '', nrCharacters = 0, useWordBoundary = false) => {
    if (nrCharacters === 0) {
      return str
    }
    let text = str.toString()
    let toLong = text.length > nrCharacters
    let newText = toLong ? text.substr(0, nrCharacters - 1) : text

    if (useWordBoundary === true) {
      let arrWords = text.split(' ')
      toLong = arrWords.length >= nrCharacters
      newText = text
      if (toLong === true) {
        arrWords.splice(nrCharacters, arrWords.length - nrCharacters)
        newText = arrWords.join(' ')
      }
    }
    return toLong ? newText + '&hellip;' : newText
  }
)

Vue.filter('nl2br', (str = '') => {
  if (str != null) {
    return str.replace(/\n/g, '<br />')
  }
  return str
})

Vue.filter('stripHtml', (str = '', brToSpace = false) => {
  if (brToSpace === true) {
    str = str.replace(/<br\s*[\/]?>/gi, ' ')
  }
  str = decodeHtml(str)
  return str.replace(/(<([^>]+)>)/gi, '')
})

function decodeHtml(html) {
  let divElm = document.createElement('div')
  divElm.innerHTML = html
  return divElm.innerText
}
function encodeHtml(txt) {
  let divElm = document.createElement('div')
  divElm.innerText = txt
  return divElm.innerHTML
}
