const state = {
  selectedWidget: null
}

const getters = {
  getSelectedWidget: state => state.selectedWidget,
}

const mutations = {
  setSelectedWidget: (state, widget) => {
    state.selectedWidget = widget
  }
}

const actions = {}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
