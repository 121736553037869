import {
  add,
  update,
  remove,
  activate,
  deactivate,
  getPackagesData,
  getPackageById
} from '@/providers/package'

import Vue from 'vue'

const state = {
  pckg: null,

  pckgs: []
}

const getters = {
  getPackage: state => state.package,

  getPackages: state => state.packages
}

const mutations = {
  setPackage: (state, pckg) => {
    state.pckg = pckg
  },

  setPackages: (state, packages) => {
    state.pckgs = packages
  }
}

const actions = {
  getAllPackages: async ({ commit }, channelId = 0) => {
    getPackagesData(channelId)
      .then((response) => {
        if (response.status === 200) {
          commit('setPackages', response.data)
        }
      })
  },

  getPackage: async ({ commit }, packageId) => {
    getPackageById(packageId)
      .then((response) => {
        if (response.status === 200) {
          commit('setPackage', response.data)
        }
      })
  },

  createPackage: async ({ state, commit }, pckg) => {
    let packages = state.pckgs
    let response = await add(pckg)
    if (response.status === 201 && response.data) {
      packages.push(response.data)
    }

    commit('setPackages', packages)

    return response
  },

  editPackage: async ({ state, commit }, pckg) => {
    let packages = state.pckgs
    let response = await update(pckg)

    if (response.status === 200 && response.data) {
      let index = packages.findIndex(b => b.Id === pckg.Id)
      if (index > -1) {
        Vue.set(packages, index, response.data)
      }
    }

    commit('setPackages', packages)

    return response
  },

  deletePackageData: async ({ state, commit }, packageId) => {
    let packages = state.pckgs
    let response = await remove(packageId)
    if (response.status === 204) {
      let index = packages.findIndex(b => b.Id === packageId)
      if (index > -1) {
        packages.splice(index, 1)
      }
    }

    commit('setPackages', packages)

    return response
  },

  activatePackage: async ({ state, commit }, packageId) => {
    let packages = state.pckgs
    let response = await activate(packageId)
    if (response.status === 200) {
      let index = packages.findIndex(b => b.Id === packageId)
      if (index > -1) {
        let updated = packages[index]
        updated.IsActive = response.data.IsActive

        Vue.set(packages, index, updated)
      }
    }

    commit('setPackages', packages)

    return response
  },

  deactivatePackage: async ({ state, commit }, packageId) => {
    let packages = state.pckgs
    let response = await deactivate(packageId)
    if (response.status === 200) {
      let index = packages.findIndex(b => b.Id === packageId)
      if (index > -1) {
        let updated = packages[index]
        updated.IsActive = response.data.IsActive

        Vue.set(packages, index, updated)
      }
    }

    commit('setPackages', packages)

    return response
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
