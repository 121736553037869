/**
 * Declare Variable
 */
const state = {
  cart: null,

  cartsPageState: {
    carts: [],
    page: 1,
    itemsPerPage: 25,
    channelId: 0,
    locationId: 0,
    reachedEnd: false,
    morePages: 1
  }
}

const getters = {
  getCart: state => state.cart,

  getCartsPageState: state => state.cartsPageState
}

const mutations = {
  setCart: (state, newCart) => {
    state.cart = newCart
  },

  setCartsPageState: (state, newPageState) => {
    state.cartsPageState = newPageState
  }
}

const actions = {}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
