import {
  getOptions
} from '@/providers/option'

/**
 * Declare Variable
 */
const state = {
  option: null,

  options: [],

  optionsPage: {
    channelId: 0,
    locationId: 0,
    categoryId: 0,
    page: 1,
    itemsPerPage: 25,
    searchTerm: '',
    selectedCategory: ''
  },

  busy: 0
}

const getters = {
  getOption: state => state.option,

  getOptions: state => state.options,

  getOptionsPage: state => state.optionsPage,

  isBusy: (state) => state.busy > 0
}

const mutations = {
  setOption: (state, newState) => {
    state.option = newState
  },

  setOptions: (state, options) => {
    state.options = options
  },

  setOptionsPage: (state, optionsPage) => {
    state.optionsPage = optionsPage
  },

  setBusy: (state, busy) => {
    state.busy = Math.max(0, busy)
  }
}

const actions = {
  clearOptionsPage: async ({ commit, state }) => {
    let sp = state.optionsPage
    sp.locationId = 0
    sp.searchTerm = ''
    sp.channelId = 0
    sp.busy = 0

    commit('setOptionsPage', sp)
    commit('setOptions', [])
  },

  saveOptionsPage: async ({ commit, dispatch }, page) => {
    commit('setOptionsPage', page)

    dispatch('getAllOptions')
  },

  getAllOptions: async ({ commit, state }) => {
    let sp = state.optionsPage

    let busy = state.busy + 1
    commit('setBusy', busy)

    state.options = []

    await getOptions(sp.locationId, sp.categoryId, sp.searchTerm, sp.seats, sp.page, sp.itemsPerPage)
      .then((response) => {
        if (response.status === 200) {
          commit('setOptions', response.data.Results)
        }
      })
      .catch(() => {
        busy = busy - 1
        commit('setBusy', busy)
      })
      .finally(() => {
        busy = busy - 1
        commit('setBusy', busy)
      })


  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
