import axios from 'axios'
import store from '@/store'

let yieldPlaygroundToken = null

export default {
  methods: {
    async getLocationYieldDays(locationId, channelId = 0, meetingtypeId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/days`,
        {
          params: {
            locationId: locationId,
            channelId: channelId,
            meetingtypeId: meetingtypeId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationYieldGroupSizes(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/groupsizes`,
        {
          params: {
            locationId: locationId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getYieldSeetingsById(yieldsettingsId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/${yieldsettingsId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getYieldSettings(
      locationId = 0,
      channelId = 0,
      spaceId = 0,
      voucherId = 0,
      meetingtypeId = 0
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/settings`,
        {
          params: {
            locationId: locationId,
            channelId: channelId,
            spaceId: spaceId,
            voucherId: voucherId,
            meetingtypeId: meetingtypeId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async builYieldPlayground(
      locationId,
      minSeats,
      maxSeats,
      nrOfDayParts,
      yieldSettings,
      percentages
    ) {
      if (yieldPlaygroundToken) {
        yieldPlaygroundToken.cancel()
      }
      yieldPlaygroundToken = axios.CancelToken.source()

      let criteria = {
        MinSeats: minSeats,
        MaxSeats: maxSeats,
        NrOfDayParts: nrOfDayParts,
        YieldSettings: yieldSettings,
        Percentages: percentages
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/location/${locationId}/playground`,
        JSON.stringify(criteria),
        {
          cancelToken: yieldPlaygroundToken.token,

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createYieldSettings(yieldSettings) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/settings`,
        yieldSettings,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createLocationYieldSettings(yieldSettings) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/location/${yieldSettings.LocationId}/settings`,
        JSON.stringify(yieldSettings),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateYieldSettings(yieldSettings) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/settings`,
        yieldSettings,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateLocationYieldSettings(yieldSettings) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/location/${yieldSettings.LocationId}/settings`,
        JSON.stringify(yieldSettings),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteYieldSettings(yieldSettingId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/yield/${yieldSettingId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
