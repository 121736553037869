import {
  //newFunctionality,
  //updateFunctionality,
  //removeFunctionality,
  getFunctionalitiesData,
  getCategoriesData,
} from '@/providers/functionality'
import Vue from 'vue'

const state = {
  functionality: null,

  functionalities: [],

  categories: []
}

const getters = {
  getFunctionality: state => state.functionality,

  getFunctionalities: state => state.functionalities,

  getCategories: state => state.categories
}

const mutations = {
  setFunctionality: (state, functionality) => {
    state.functionality = functionality
  },

  setFunctionalities: (state, functionalities) => {
    state.functionalities = functionalities
  },

  setCategories: (state, categories) => {
    state.categories = categories
  }
}

const actions = {
  getAllCategories: async ({ commit }) => {
    getCategoriesData()
      .then((response) => {
        if (response.status === 200) {
          commit('setCategories', response.data)
        }
      })
  },

  getAllFunctionalities: async ({ commit }) => {
    getFunctionalitiesData()
      .then((response) => {
        if (response.status === 200) {
          commit('setFunctionalities', response.data)
        }
      })
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}