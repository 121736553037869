import {
  newBundle,
  updateBundle,
  removeBundle,
  getBundlesData,
} from '@/providers/bundle'
import Vue from 'vue'

const state = {
  bundle: null,

  bundles: []
}

const getters = {
  getBundle: state => state.bundle,

  getBundles: state => state.bundles
}

const mutations = {
  setBundle: (state, bundle) => {
    state.bundle = bundle
  },

  setBundles: (state, bundles) => {
    state.bundles = bundles
  }
}

const actions = {
  getAllBundles: async ({ commit }, channelId) => {
    getBundlesData(channelId)
      .then((response) => {
        if (response.status === 200) {
          commit('setBundles', response.data)
        }
      })
  },

  createBundle: async ({ state, commit }, bundle) => {
    let bundles = state.bundles
    let response = await newBundle(bundle.ChannelId, bundle)
    if (response.status === 200 && response.data) {
      bundles.push(response.data)
    }

    commit('setBundles', bundles)

    return response
  },

  editBundle: async ({ state, commit }, bundle) => {
    let bundles = state.bundles
    let response = await updateBundle(bundle.ChannelId, bundle)

    if (response.status === 200 && response.data) {
      let index = bundles.findIndex(b => b.Id === bundle.Id)
      if (index > -1) {
        Vue.set(bundles, index, response.data)
      }
    }

    commit('setBundles', bundles)

    return response
  },

  deleteBundle: async ({ state, commit }, bundle) => {
    let bundles = state.bundles
    let response = await removeBundle(bundle.ChannelId, bundle)
    if (response.status === 204) {
      let index = bundles.findIndex(b => b.Id === bundle.Id)
      if (index > -1) {
        bundles.splice(index, 1)
      }
    }

    commit('setBundles', bundles)

    return response
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
