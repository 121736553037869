<template>
  <div>
    <ui-page-hero
      v-if="showHero"
      :title="heroTitle"
      :subTitle="heroSubTitle"
      :additionalClass="heroAdditionalClass"
      :additionalTitleClass="hereAdditionalTitleClass"
      :additionalSubTitleClass="heroAdditionalSubTitleClass"
    >
      <template v-slot:quickActions>
        <slot name="pageHeroQuickAction"></slot>
      </template>
      <template v-slot:subSection>
        <slot name="pageHeroSubSection"></slot>
      </template>
    </ui-page-hero>
    <div class="columns pageContent">
      <div class="column column-menu is-hidden-print">
        <slot name="pageMenu"></slot>
      </div>
      <div
        class="column column-content"
        :class="{ 'has-overflow-hidden-y': overflowHiddenY }"
      >
        <transition
          name="slide-fade"
          mode="out-in"
          v-on:after-enter="afterEnter"
          v-on:enter="enter"
          v-on:after-leave="afterLeave"
          v-on:leave="leave"
        >
          <slot name="pageContent"></slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showHero: {
      type: Boolean,
      default: true,
    },
    heroAdditionalClass: {
      type: String,
      default: '',
    },
    hereAdditionalTitleClass: {
      type: String,
      default: '',
    },
    heroAdditionalSubTitleClass: {
      type: String,
      default: '',
    },
    heroTitle: {
      type: String,
      default: '',
    },
    heroSubTitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      overflowHiddenY: false,
    }
  },

  computed: {
    ...mapState('menuStore', ['selectedMenuItems']),
  },

  methods: {
    afterEnter() {
      this.overflowHiddenY = false
    },
    enter() {
      this.overflowHiddenY = true
    },
    afterLeave() {
      this.overflowHiddenY = false
    },
    leave() {
      this.overflowHiddenY = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.has-overflow-hidden-y {
  overflow-y: hidden;
}
.pageContent {
  .column-menu {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 210px;
  }
  .column-content {
    // padding-left: $gap;
    // padding-right: $gap;
    // margin-right: -$gap;
    flex-grow: 1;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    .column {
      flex-basis: unset;
      flex-basis: unset;
    }
  }
}
</style>
