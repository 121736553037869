import { eraseCookie, getCookie, setCookie } from '@/utils/browserStorage'
import loginProvider from '../../providers/login'
import profileProvider from '../../providers/profile'

/**
 * Declare Variable
 */
const cookieExpireAfterNrDays = 30

const state = {
  profile: null,
  profileState: null,
  profileToken: ''
}

const getters = {
  isLoggedIn: state => {
    return state.profile !== null && state.profile.Key.length && state.profileToken.length ? true : false
  },
  getIsLoggedIn: (state) => {
    return state.profile !== null && state.profileToken.length ? true : false
  },
  getProfile: state => state.profile,
  getProfileToken: state => state.profileToken,
  getProfileState: state => state.profileState
}

const mutations = {
  /**
   * Set profile data in state
   */
  setProfile: (state, newState) => {
    state.profile = newState
  },

  /**
   * Set profile state in store
   */
  setProfileState: (state, newState) => {
    state.profileState = newState
  },

  /**
   * Set profile token in state
   */
  setProfileToken: (state, newState) => {
    state.profileToken = newState
  }
}

const actions = {
  /**
   * Clear profile token
   * @param {Object} context
   */
  async logoff(context) {
    let apCookie = getCookie('ap')
    let ltCookie = getCookie('lt')

    if (apCookie !== '' || ltCookie !== '') {
      await loginProvider.methods
        .logoff()
        .then((response) => { })
        .catch((e) => {
          //console.log(e)
        })
        .finally(() => {
          eraseCookie('ap')
          eraseCookie('lt')
          context.dispatch('clearProfile')
          context.dispatch('clearProfileToken')
          let t = setTimeout(function() {
            window.location.reload(true)
            clearTimeout(t)
          }, 250)
        })
    }
  },

  /**
   * Clear profile
   * @param {Object} context
   */
  clearProfile(context) {
    context.commit('setProfile', null)
  },

  /**
   * Clear profile state
   * @param {Object} context
   */
  clearProfileState(context) {
    context.commit('setProfileState', null)
  },

  /**
   * Clear profile token
   */
  clearProfileToken(context) {
    context.commit('setProfileToken', '')
  },

  /**
   * Get profile data and store it
   * @param {Object} context
   */
  async getProfileData(context) {
    await profileProvider.methods
      .getProfile()
      .then(response => {
        // Set cookie
        setCookie('ap', response.data.Key, cookieExpireAfterNrDays, true)

        // Set profile in store
        context.commit(
          'setProfile', response.data
        )
      })
      .catch(e => {
        //console.log(e)
      })
      .finally(() => { })
  },

  /**
   * Get profile state data
   * @param {Object} context
   */
  async getProfileState(context) {
    await profileProvider.methods
      .getProfileState()
      .then(response => {
        if (response.status === 200) {
          context.commit('setProfileState', response.data)
        }
      })
      .catch(e => { })
      .finally(() => {
        // Update app startup load status
        context.dispatch('updateAppStartupLoadStatus', 'profileData', { root: true })
      })
  },

  /**
   * Save profile token in store and get profile date
   */
  saveProfileToken: async (context, profileToken) => {
    // Set cookie
    setCookie('lt', profileToken, cookieExpireAfterNrDays, true)

    // Set profile token in store
    context.commit('setProfileToken', profileToken)

    // Get profile data
    await context.dispatch('getProfileData')

    // Get Profile state
    await context.dispatch('getProfileState')

    // Update app startup load status
    context.dispatch('updateAppStartupLoadStatus', 'verifyProfileToken', { root: true })
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
