<template>
  <div id="app">
    <router-view key="ready" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { eraseCookie, getCookie, setCookie } from './utils/browserStorage'

import loginProvider from '@/providers/login'
import profileProvider from '@/providers/profile'

//const TopBar = () => import('@/components/MainSiteElements/TopBar')

export default {
  components: {
    //'top-bar': TopBar,
  },

  data() {
    return {
      signalRIsSetup: false,
    }
  },

  beforeCreate() {
    this.$nextTick().then(() =>
      document.documentElement.classList.add('frontend-environment')
    )
  },

  created() {
    let cMT = getCookie('mt')
    if (cMT === '') {
      setCookie('mt', this.generateGuid(), 100, true)
    }

    let t = setTimeout(() => {
      this.getProfileTokenFromCookie()
      clearTimeout(t)
    }, 100)
  },

  computed: {
    ...mapState(['appStartupLoadReady']),
    ...mapState('channelStore', ['channel']),
    ...mapState('profileStore', ['isLoggedIn']),
  },

  methods: {
    ...mapActions('profileStore', ['logoff', 'saveProfileToken']),

    generateGuid() {
      return (
        this.s4() +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        this.s4() +
        this.s4()
      )
    },

    s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    },

    getProfileTokenFromCookie() {
      let apCookie = getCookie('ap')
      let ltCookie = getCookie('lt')

      if (apCookie === '' || ltCookie === '') {
        this.logoff()

        // Update app startup load status
        this.$store.dispatch('updateAppStartupLoadStatus', 'profileData')
        this.$store.dispatch('updateAppStartupLoadStatus', 'verifyProfileToken')
      } else {
        if (ltCookie !== '') {
          // Verify if token is still valid
          this.verifyProfileToken(ltCookie)
        }
      }
    },

    verifyProfileToken(profileToken) {
      if (profileToken) {
        loginProvider.methods
          .verifyProfileToken(profileToken)
          .then((response) => {
            if (response.status === 200) {
              if (response.data) {
                this.saveProfileToken(profileToken)
              } else {
                this.logoff()
              }
            }
          })
          .catch((e) => { })
          .finally(() => { })
      }
    },
  },
}
</script>

<style lang="scss">
@import "./assets/styles/main.scss";
</style>
