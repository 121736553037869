import yieldProvider from '@/providers/yield'

/**
 * Declare Variable
 */
const state = {
  yieldSettingsList: {
    selectedChannelId: 0,
    showVouchers: false,
    yieldSettings: [],
  },

  yieldSettingsPlayground: null,

  yieldSettingsState: {
    isSavingSuccess: false,
    isSavingError: false,
    yieldSettings: null
  }
}

const getters = {
  getYieldSettingsPlayground: state => state.yieldSettingsPlayground,

  getYieldSettingsState: state => state.yieldSettingsState
}

const actions = {
  saveYieldSettings({ commit, state }, data) {
    yieldProvider.methods
      .updateLocationYieldSettings(data.yieldSettings)
      .then(response => {
        if (response.status === 204) {
          data.isSavingSuccess = true
        }
      })
      .catch(error => {
        data.isSavingError = true
      })
      .finally(() => {
        commit('setYieldSettingsState', data)
      })
  }
}

const mutations = {
  setYieldSettingsList: (state, newState) => {
    state.yieldSettingsList = newState
  },

  setYieldSettingsPlayground: (state, newState) => {
    state.yieldSettingsPlayground = newState
  },

  setYieldSettingsState: (state, newState) => {
    state.yieldSettingsState = newState
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
