const reportRoutes = {
  path: 'reports',
  name: 'reportsOverview',
  components: {
    default: () => import('@/views/PageRoutView')
  },
  redirect: { name: 'reports' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/:channelId/reports/fee',
      name: 'reports-fee',
      component: () => import('@/views/Reports/ChannelFee'),
      meta: {
        requiresAuth: true
      }
    },
  ]
}

export default reportRoutes
