/**
 * Declare Variable
 */
const state = {
  voucher: null,

  vouchersPageState: {
    bottom: false,
    page: 1,
    itemsPerPage: 250,
    searchTerm: '',
    type: -1,
    vouchers: [],
    reachedEnd: false
  }
}

const getters = {
  getVoucher: state => state.voucher,
  getVouchersPageState: state => state.vouchersPageState
}

const actions = {
  setVouchersPageState: (state, newPageState) => {
    state.vouchersPageState = newPageState
  }
}

const mutations = {
  setVoucher: (state, newState) => {
    state.voucher = newState
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
