import localforage from 'localforage'

/**
 * Config
 * Documentation: https://github.com/localForage/localForage
 */
localforage.config({
  name: 'pms-manage',
})

/**
 * Methods
 */
export default {
  async get(key) {
    try {
      const response = await localforage.getItem(key)
      return response
    } catch (err) {
      // This code runs if there were any errors.
      //console.log(err)
      return err
    }
  },

  async set(key, value) {
    try {
      const response = await localforage.setItem(key, value)
      return response
    } catch (err) {
      // This code runs if there were any errors.
      //console.log(err)
      return err
    }
  },

  async delete(key) {
    try {
      const response = await localforage.removeItem(key)
      return response
    } catch (err) {
      // This code runs if there were any errors.
      return err
    }
  },
}
