import {
  getSpaces
} from '@/providers/space'

/**
 * Declare Variable
 */
const state = {
  space: null,

  spaces: [],

  spacesPage: {
    locationId: 0,
    bottom: false,
    page: 0,
    itemsPerPage: 25,
    searchTerm: "",
    reachedEnd: false,
    meetingtypeId: 0,
    seats: 0,
    busy: 0
  },

  busy: 0
};

const getters = {
  getSpace: (state) => state.space,
  getSpacesPage: (state) => state.spacesPage,
  isBusy: (state) => state.busy > 0
}

const mutations = {
  setSpace: (state, space) => {
    state.space = space;
  },

  setSpaces: (state, spaces) => {
    state.spaces = spaces;
  },

  setSpacesPage: (state, pageState) => {
    state.spacesPage = pageState;
  },

  setBusy: (state, busy) => {
    state.busy = Math.max(0, busy)
  }
}

const actions = {
  clearSpacesPage: async ({ commit, state }) => {
    let sp = state.spacesPage
    sp.locationId = 0
    sp.searchTerm = ''
    sp.meetingtypeId = 0

    commit('setSpacesPage', sp)
    commit('setSpaces', [])
  },

  saveSpacesPage: async ({ commit, dispatch }, page) => {
    commit('setSpacesPage', page)

    dispatch('getLocationSpaces')
  },

  getLocationSpaces: async ({ commit, state }) => {
    let sp = state.spacesPage
    commit('setSpacesPage', sp)

    let busy = state.busy + 1
    commit('setBusy', busy)

    state.spaces = []

    getSpaces(sp.locationId, sp.searchTerm, sp.meetingtypeId, sp.seats, sp.page, sp.itemsPerPage)
      .then((response) => {
        if (response.status === 200) {
          commit('setSpaces', response.data.Results)
        }
      })
      .catch(() => {
        busy = busy - 1
        commit('setBusy', busy)
      })
      .finally(() => {
        busy = busy - 1
        commit('setBusy', busy)
      })
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
