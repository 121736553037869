import axios from "axios"
import store from "@/store"

let getFeeInvoicesToken = null
let getNoInvoiceLocationsToken = null

export const getNoInvoiceLocations = (channelId, year, month = 0, locationId = 0) => {
  if (getNoInvoiceLocationsToken) {
    getNoInvoiceLocationsToken.cancel()
  }
  getNoInvoiceLocationsToken = axios.CancelToken.source()

  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/noinvoice`,
    {
      cancelToken: getNoInvoiceLocationsToken.token,
      params: {
        channelId: channelId,
        year: year,
        month: month,
        locationId: locationId
      },

      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const getInvoicesData = (channelId, year, month = 0, locationId = 0, searchTerm = "", statusIds) => {
  if (getFeeInvoicesToken) {
    getFeeInvoicesToken.cancel()
  }
  getFeeInvoicesToken = axios.CancelToken.source()

  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice`,
    {
      cancelToken: getFeeInvoicesToken.token,
      params: {
        channelId: channelId,
        locationId: locationId,
        month: month,
        year: year,
        q: searchTerm,
        statusIds: statusIds
      },
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const create = (channelId, year, month, locationId) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/month`,
    {
      ChannelId: channelId,
      Month: month,
      Year: year,
      LocationId: locationId
    },
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const credit = (invoiceId) => {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}/credit`,
    null,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {
    async getInvoiceById(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getFeeInvoices(channelId, locationId, month, year, searchTerm = "", statusIds) {
      if (getFeeInvoicesToken) {
        getFeeInvoicesToken.cancel()
      }
      getFeeInvoicesToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice`,
        {
          cancelToken: getFeeInvoicesToken.token,
          params: {
            channelId: channelId,
            locationId: locationId,
            month: month,
            year: year,
            q: searchTerm,
            statusIds: statusIds
          },
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationsWithoutInvoice(channelId, year, month = 0, locationId = 0) {
      if (getNoInvoiceLocationsToken) {
        getNoInvoiceLocationsToken.cancel()
      }
      getNoInvoiceLocationsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/noinvoice`,
        {
          cancelToken: getNoInvoiceLocationsToken.token,
          params: {
            channelId: channelId,
            year: year,
            month: month,
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async markAsPaid(invoiceId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}/paid`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async markAsOpen(invoiceId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}/open`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async sendInvoiceLocationMail(invoiceId) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}/sendmail`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async sendFirstReminder(invoiceId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}/reminder/first`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async sendSecondReminder(invoiceId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/feeinvoice/${invoiceId}/reminder/second`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getFeeInvoicePdf(invoiceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/feeinvoice/${invoiceId}/pdf`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
