import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { getCookie } from '@/utils/browserStorage'

let verifyProfileTokenCancelToken = null
let resetPasswordToken = null

export default {
  methods: {
    cancelVerifyProfileToken() {
      if (verifyProfileTokenCancelToken) {
        verifyProfileTokenCancelToken.cancel()
      }
    },

    /**
     * User login
     * @param {String} email
     * @param {String} password
     */
    async authorizeUser(email, password) {
      var criteria = {
        email: email,
        password: password,
        machineToken: getCookie('mt'),
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/login`,
        JSON.stringify(criteria),
        {
          params: {
            minutes: Vue.options.filters.timeToMinutes(new Date()),
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
          },
        }
      )
      return response
    },

    /**
     * Verify profiletoken
     * @param {String} email
     * @param {String} password
     */
    async verifyProfileToken(profileToken) {
      this.cancelVerifyProfileToken()
      verifyProfileTokenCancelToken = axios.CancelToken.source()

      if (profileToken) {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/v1/login/verify/token`,
          '',
          {
            cancelToken: verifyProfileTokenCancelToken.token,
            headers: {
              'Content-type': 'application/json',
              apiToken: process.env.VUE_APP_API_KEY,
              profileToken: profileToken,
            },
          }
        )
        return response
      }
    },

    async changePassword(token = '', pin = '', password) {
      if (resetPasswordToken) {
        resetPasswordToken.cancel('Request cancelled')
      }
      resetPasswordToken = axios.CancelToken.source()

      let criteria = {
        Password: password,
        Token: token,
        Pin: pin,
      }

      return axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/login/password`,
        JSON.stringify(criteria),
        {
          cancelToken: resetPasswordToken.Token,
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
          },
        }
      )
    },

    resetPassword: async (email) => {
      if (email) {
        let criteria = {
          ChannelId: 1,
          Email: email,
        }

        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/v1/login/reset`,
          JSON.stringify(criteria),
          {
            headers: {
              'Content-type': 'application/json',
              apiapiToken: process.env.VUE_APP_API_KEY,
              apiToken: process.env.VUE_APP_API_KEY,
              profileToken: store.getters['profileStore/getProfileToken'],
            },
          }
        )
        return response
      }
    },

    async logoff() {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/logoff`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },
  },
}
