import Vue from 'vue'

Vue.filter('getStatusName', function(value) {
  let name = ''

  switch (value) {
    case -1:
      name = 'Denied'
      break
    case 0:
      name = 'Pending approval'
      break
    case 1:
      name = 'Optional'
      break
    case 2:
      name = 'Final'
      break
    case 3:
      name = 'Cancelled'
      break
  }

  return name
})