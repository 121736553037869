import hash from 'object-hash'
import { cleanSource } from '@/utils/objectHelper'

const structure = {
  modifiedDataSet: null,
  originalDataSet: null,
  hashedModifiedDataSet: null,
  hashedOriginalDataSet: null,
  differencesDiscovered: false
}

/**
 * Store states
 */
const state = {
  modifiedDataSet: null,
  originalDataSet: null,
  hashedModifiedDataSet: null,
  hashedOriginalDataSet: null,
  differencesDiscovered: false
}

/**
 * Store getters
 */
const getters = {
  getModifiedDataSet: state => state.modifiedDataSet,
  getOriginalDataSet: state => state.originalDataSet,
  getDifferencesDiscovered: state => state.differencesDiscovered
}

/**
 * Store mutations
 */
const mutations = {
  /**
   * Store modified data set and check if there are diffences
   */
  setModifiedDataSet: (state, newState) => {
    state.modifiedDataSet = cleanSource(newState)
    state.hashedModifiedDataSet = hash(state.modifiedDataSet)
    state.differencesDiscovered =
      state.hashedModifiedDataSet !== state.hashedOriginalDataSet
  },

  /**
   * Store original data set
   */
  setOriginalDataSet: (state, newState) => {
    state.originalDataSet = cleanSource(newState)
    state.hashedOriginalDataSet = hash(newState)
  }
}

/**
 * Store actions
 */
const actions = {
  /**
   * Clear store date
   */
  clearVersionStoreDataSets({ state }) {
    state.originalDataSet = null
    state.hashedModifiedDataSet = null
    state.hashedOriginalDataSet = null
    state.differencesDiscovered = false
  },

  /**
   * Fill store with new date sets
   */
  fillVersionStoreDataSets({ commit }, data) {
    commit('setOriginalDataSet', data)
    commit('setModifiedDataSet', data)
  },

  undoVersionStoreModifications({ commit, state }) {
    commit('setModifiedDataSet', cleanSource(state.originalDataSet))
  }
}

export default {
  // namespaced: true,
  state,
  getters,
  mutations,
  actions
}
