const state = {
  tag: null,

  tagsPageState: {
    amountChoices: [3, 10, 25, 50, 100],
    nrOfTags: 25,
    page: 1,
    itemsPerPage: 25,
    searchTerm: "",
    selectedTag: 0,
    selectedTab: 'month',
    tags: [],
    tagUsageItems: [],
    year: new Date().getFullYear()
  },
}

const getters = {
  getTag: state => state.tag,

  getTagsPageState: state => state.tagsPageState
}

const mutations = {
  setTag: (state, newState) => {
    state.tag = newState
  },

  setTagsPageState: (state, newState) => {
    state.tagsPageState = newState
  }
}

const actions = {}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
