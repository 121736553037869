import {
  getNoInvoiceLocations,
  getInvoicesData,
  create,
  credit
} from '@/providers/feeInvoice'

/**
 * Declare Variable
 */
const state = {
  invoice: null,
  invoicesPageState: {
    invoices: [],
    locationId: 0,
    noInvoiceLocations: [],
    searchTerm: "",
    selectedStatusId: 1,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    busy: 0,

  },

  settings: {
    locationId: 0,
    searchTerm: "",
    selectedStatusId: 1,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    statusIds: '1,2,3'
  },


  locations: [],
  locationsLoading: false,


  invoices: [],
  invoicesLoading: false
}

const getters = {
  isBusy: state => state.busy > 0,

  getInvoice: state => state.invoice,
  getInvoicesPageState: state => state.invoicesPageState,

  getSettings: state => state.settings,
  getLocations: state => state.locations,
  getInvoices: state => state.invoices
}

const mutations = {
  setInvoice(state, newState) {
    state.invoice = newState
  },

  setInvoicesPageState(state, newState) {
    state.invoicesPageState = newState
  },

  setSettings(state, settings) {
    state.invoice = settings
  },

  setLocations(state, locations) {
    state.locations = locations
  },

  setLocationsLoading(state, loading) {
    state.locationsLoading = loading
  },

  setInvoices(state, invoices) {
    state.invoices = invoices
  },

  setInvoicesLoading(state, loading) {
    state.invoicesLoading = loading
  },
}

const actions = {
  updateSettings: async ({ commit, dispatch }, settings) => {
    commit('setSettings', settings)

    dispatch('getAllInvoices')
  },

  getAllInvoices: async ({ state, commit }) => {
    let settings = state.settings

    //state.busy = state.busy + 1

    commit('setLocations', [])
    commit('setLocationsLoading', true)
    commit('setInvoices', [])
    commit('setInvoicesLoading', true)

    getNoInvoiceLocations(settings.channelId, settings.year, settings.month, settings.locationId)
      .then((response) => {
        if (response.status === 200) {
          commit('setLocations', response.data)
        }
      })
      .finally(() => {
        commit('setLocationsLoading', false)
      })

    getInvoicesData(settings.channelId, settings.year, settings.month, settings.locationId, settings.searchTerm, settings.statusIds)
      .then((response) => {
        if (response.status === 200) {
          commit('setInvoices', response.data)
        }
      })
      .finally(() => {
        commit('setInvoicesLoading', false)
      })

    //state.busy = state.busy - 1
  },

  createInvoices: async ({ state, commit }, criteria) => {
    let settings = state.settings
    let locations = state.locations

    create(criteria.channelId, criteria.year, criteria.month, criteria.locationId)
      .then(response => {
        if (response.status === 200) {
          if (settings.month === criteria.month && settings.year === criteria.year) {

            let locationIndex = locations.findIndex(l => l.Id === criteria.locationId)
            if (locationIndex > -1) {
              locations.splice(locationIndex, 1)

              commit('setLocations', locations)
            }

          }
        }
      })
  },

  creditInvoice: async ({ state, commit }, invoiceId) => {
    let settings = state.settings
    let invoices = state.invoices
    let locations = state.locations

    credit(invoiceId)
      .then(response => {
        if (response.status === 200) {
          let invoiceIndex = invoices.findIndex(i => i.Id === invoiceId)

          if (invoiceIndex > -1) {
            invoices.splice(invoiceIndex, 1)
            commit('setInvoices', invoices)
          }

          if (settings.month === response.data.Month && settings.year === response.data.Year) {
            locations.push({ Id: response.data.LocationId, Name: response.data.LocationName, Channels: '', Fee: 0 })
            commit('setLocations', locations)
          }
        }
      })
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
