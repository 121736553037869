import {
  create,
  remove,
  update,
} from '@/providers/subscription'

import Vue from 'vue'

/**
 * Declare Variable
 */
const state = {
  subscription: null,
  subscriptionsPageState: {
    subscriptions: [],
    channelId: 0,
    locationId: 0,
    noSubscriptionLocations: [],
    searchTerm: "",
    selectedStatusId: 0,
    packageId: 0,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    onlyCancelled: false,
    onlyFreeTrial: false,
    onlyRecurring: false
  }
}

const getters = {
  getSubscription: state => state.subscription,
  getSubscriptionsPageState: state => state.subscriptionsPageState
}

const mutations = {
  setSubscription(state, newState) {
    state.subscription = newState
  },

  setSubscriptionsPageState(state, newState) {
    state.subscriptionsPageState = newState
  }
}

const actions = {
  deleteSubscription: async ({ state, commit }, subscription) => {
    let pageState = state.subscriptionsPageState
    let response = await remove(subscription)

    if (response.status === 204) {
      let index = pageState.subscriptions.findIndex(s => s.Id === subscription.Id)
      if (index > -1) {
        pageState.subscriptions.splice(index, 1)
      }
    }

    commit('setSubscriptionsPageState', pageState)

    return response
  },

  editSubscription: async ({ state, commit }, subscription) => {
    let pageState = state.subscriptionsPageState
    let response = await update(subscription)

    if (response.status === 200 && response.data) {
      let index = pageState.subscriptions.findIndex(s => s.Id === subscription.Id)
      if (index > -1) {
        Vue.set(pageState.subscriptions, index, response.data)
      }
    }

    commit('setSubscriptionsPageState', pageState)

    return response
  },

  addSubscription: async ({ state, commit }, subscription) => {
    let pageState = state.subscriptionsPageState
    let response = await create(subscription)

    if (response.status === 200 && response.data) {
      pageState.subscriptions.push(response.data)
    }

    commit('setSubscriptionsPageState', pageState)

    return response
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
