import axios from 'axios'
import store from '../store'

let getOptionToken = null
let getOptionsToken = null


export const getOptions = (
  locationId = 0,
  categoryId = 0,
  searchTerm = '',
  page = 0,
  itemsPerPage = 0) => {
  if (getOptionsToken) {
    getOptionsToken.cancel()
  }
  getOptionsToken = axios.CancelToken.source()

  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/location/${locationId}`,
    {
      cancelToken: getOptionsToken.token,
      params: {
        categoryId: categoryId,
        q: searchTerm,
        page: page,
        itemsPerPage: itemsPerPage
      },

      headers: {
        'Content-type': 'application/json',
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {
    async getOptionById(optionId, locationId) {
      if (getOptionToken) {
        getOptionToken.cancel()
      }
      getOptionToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}`,
        {
          cancelToken: getOptionToken.token,

          params: {
            locationid: locationId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationOptions(
      locationId = 0,
      categoryId = 0,
      searchTerm = '',
      page = 0,
      itemsPerPage = 0
    ) {
      if (getOptionsToken) {
        getOptionsToken.cancel()
      }
      getOptionsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/location/${locationId}`,
        {
          cancelToken: getOptionsToken.token,
          params: {
            categoryId: categoryId,
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelOptions(
      channelId = 0,
      categoryId = 0,
      searchTerm = '',
      page = 0,
      itemsPerPage = 0
    ) {
      if (getOptionsToken) {
        getOptionsToken.cancel()
      }
      getOptionsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/channel/${channelId}`,
        {
          cancelToken: getOptionsToken.token,
          params: {
            categoryId: categoryId,
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveOptionBase(optionId, optionBase) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/base`,
        optionBase,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async saveLocationOptionSettings(optionLocation) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionLocation.OptionId}/settings`,
        optionLocation,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationOptionImage(optionId, locationId, imageName) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/image`,
        JSON.stringify({
          Image: imageName,
          LocationId: locationId
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationOptionChannels(optionId, locationId, channels) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/channels`,
        JSON.stringify({
          OptionId: optionId,
          LocationId: locationId,
          Channels: channels
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationOptionMeetingtypes(optionId, locationId, meetingtypes) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/meetingtypes`,
        JSON.stringify({
          OptionId: optionId,
          LocationId: locationId,
          Meetingtypes: meetingtypes
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationOptionPrices(optionId, locationId, prices) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/prices`,
        JSON.stringify({
          OptionId: optionId,
          LocationId: locationId,
          Prices: prices
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationOptionTexts(optionId, locationId, texts) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/texts`,
        JSON.stringify({
          OptionId: optionId,
          LocationId: locationId,
          Texts: texts
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationOptions(
      reservationId,
      categoryId = 0,
      searchTerm = ''
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/available/options`,
        {
          params: {
            q: searchTerm,
            cat: categoryId,
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async getCartOptions(cartKey, categoryId = 0, searchTerm = '') {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/${cartKey}/option`,
        {
          params: {
            categoryId: categoryId,
            q: searchTerm
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveCartOption(
      cartKey,
      optionId,
      categoryId,
      amount,
      currencyId,
      price,
      priceTotal,
      crc,
      taxId
    ) {
      let criteria = {
        CartKey: cartKey,
        OptionId: optionId,
        CategoryId: categoryId,
        Amount: amount,
        CurrencyId: currencyId,
        PricePerItem: price,
        PriceTotal: priceTotal,
        Crc: crc,
        TaxId: taxId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/cart/option`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteCartOption(cartKey, optionId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/cart/${cartKey}//option/${optionId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createChannelOption(newOption) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/channel`,
        JSON.stringify(newOption),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createLocationOption(newOption) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/location`,
        JSON.stringify(newOption),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationRankedOptions(locationId, meetingtypeId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/location/${locationId}/ranked`,
        {
          params: {
            meetingtypeId: meetingtypeId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationRankedOptions(locationId, options) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/location/${locationId}/ranked`,
        JSON.stringify(options),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteOptionLocation(optionId, locationId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteOptionChannel(optionId, channelId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/channel/${channelId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationEditSettings(optionId, settings) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/${optionId}/edit/rights`,
        settings,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async checkOption(channelId, locationId, optionId) {
      let criteria = {
        ChannelId: channelId,
        LocationId: locationId,
        OptionId: optionId,
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/option/check`, criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
