import Vue from 'vue'

Vue.filter('getEventTypeName', (eventType) => {
  let name = ''

  switch (eventType) {
    case 1:
      name = 'Meeting'
      break
    case 2:
      name = 'Training'
      break
    case 3:
      name = 'Brainstorm session'
      break
    case 4:
      name = 'Event'
      break
    case 5:
      name = 'Reception'
      break
    case 6:
      name = 'Network lunch'
      break
    case 7:
      name = 'Product launch'
      break
    case 8:
      name = 'Presentation'
      break
    case 9:
      name = 'Open space'
      break
    case 10:
      name = 'Bar camp'
      break
    case 11:
      name = 'Slam'
      break
    case 12:
      name = 'Speed geeking'
      break
    case 13:
      name = 'Business modelling'
      break
    case 14:
      name = 'Fuck up night'
      break
    case 15:
      name = 'World cafe'
      break
    case 16:
      name = 'Mutliroom single day workshop'
      break
  }
  return name
})
