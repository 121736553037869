import axios from "axios";
import store from "../store";

let getLocationMeetingspacesToken = null;
let getLocationSpacesToken = null;
let getMeetingspaceToken = null;

export const getSpaces = (locationId,
  searchTerm,
  meetingtypeId = 0,
  seats = 0,
  page = 1,
  itemsPerPage = 50) => {
  if (getLocationSpacesToken) {
    getLocationSpacesToken.cancel();
  }
  getLocationSpacesToken = axios.CancelToken.source();

  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/location/${locationId}`,
    {
      cancelToken: getLocationSpacesToken.token,

      params: {
        q: searchTerm,
        meetingtypeId: meetingtypeId,
        seats: seats,
        page: page,
        itemsPerPage: itemsPerPage
      },

      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {
    async getSpaceById(spaceId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/${spaceId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getSpaces(
      locationId,
      searchTerm,
      meetingtypeId = 0,
      seats = 0,
      page = 1,
      itemsPerPage = 50
    ) {
      if (getLocationSpacesToken) {
        getLocationSpacesToken.cancel();
      }
      getLocationSpacesToken = axios.CancelToken.source();

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/location/${locationId}`,
        {
          cancelToken: getLocationSpacesToken.token,

          params: {
            q: searchTerm,
            meetingtypeId: meetingtypeId,
            seats: seats,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getLocationMeetingspaces(locationId, searchTerm, page, itemsPerPage) {
      if (getLocationMeetingspacesToken) {
        getLocationMeetingspacesToken.cancel();
      }
      getLocationMeetingspacesToken = axios.CancelToken.source();

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/meetingspace/location/${locationId}`,
        {
          cancelToken: getLocationMeetingspacesToken.token,

          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getMeetingspace(spaceId) {
      if (getMeetingspaceToken) {
        getMeetingspaceToken.cancel();
      }
      getMeetingspaceToken = axios.CancelToken.source();

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/meetingspace/${spaceId}`,
        {
          cancelToken: getMeetingspaceToken.token,

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getActiveSpaceConfigurations(locationId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/configurations/active`,
        {
          params: {
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async createSpace(space) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space`,
        JSON.stringify(space),
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async updateSpace(space) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/${space.Id}`,
        space,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async combineSpaces(locationId, spaceIds) {
      let criteria = {
        LocationId: locationId,
        SpaceIds: spaceIds
      };

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/combine`,
        JSON.stringify(criteria),
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async deleteSpaceById(spaceId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/${spaceId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async deleteSpaceImageById(spaceId, imageId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/${spaceId}/image/${imageId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async checkSpace(channelId, locationId, spaceId, meetingtypeId) {
      let criteria = {
        ChannelId: channelId,
        LocationId: locationId,
        SpaceId: spaceId,
        MeetingtypeId: meetingtypeId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/space/check`, criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
};
