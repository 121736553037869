import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18nConfig from './i18n'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowsAlt,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faTasks,
  faInbox,
  faPlus,
  faAngleLeft,
  faAngleRight,
  faFileInvoice,
  faClone,
  faTicketAlt,
  faCalendarAlt,
  faBan,
  faCoins,
  faCopy,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faAt,
  faUserCheck,
  faChartBar,
  faChartPie,
  faSearch,
  faCheck,
  faTimes,
  faTrashAlt,
  faEdit,
  faThumbtack,
  faBookmark,
  faClock,
  faHistory,
  faQuestionCircle,
  faShare,
  faLink,
  faFileDownload,
  faImage,
  faEnvelope,
  faShoppingBasket,
  faSearchMinus,
  faSearchPlus,
  faUndo,
  faRedo,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faPaperPlane,
  faStickyNote,
  faComment,
  faReply,
  faTable,
  faStar,
  faExpandAlt,
  faFile,
  faCog,
  faUser,
  faMinus,
  faArrowsAltH,
  faUpload,
  faCalendarTimes,
  faMobileAlt,
  faEye,
  faEyeSlash,
  faLock,
  faLockOpen,
} from '@fortawesome/free-solid-svg-icons'
import {
  faBullseyePointer,
  faChartNetwork,
} from '@fortawesome/pro-solid-svg-icons'
// import { } from '@fortawesome/pro-duo-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faChevronRight as farChevronRight,
  faMinus as farMinus,
  faCheckSquare as farCheckSquare,
  faSquare as farSquare,
  faStickyNote as farStickyNote,
  faShredder as farShredder,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faArrowsAlt,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faTasks,
  faInbox,
  faPlus,
  faAngleLeft,
  faAngleRight,
  faFileInvoice,
  faClone,
  faCoins,
  faTicketAlt,
  faLock,
  faLockOpen,
  faCalendarAlt,
  faBan,
  faCopy,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faEnvelope,
  faImage,
  faInfoCircle,
  faAt,
  faUserCheck,
  faChartBar,
  faChartPie,
  faSearch,
  faCheck,
  faTimes,
  faTrashAlt,
  faEdit,
  faThumbtack,
  faBookmark,
  faClock,
  faHistory,
  faQuestionCircle,
  faShare,
  faLink,
  faFileDownload,
  faShoppingBasket,
  faSearchMinus,
  faSearchPlus,
  faUndo,
  faRedo,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faPaperPlane,
  faStickyNote,
  faComment,
  faReply,
  faTable,
  faStar,
  faExpandAlt,
  faFile,
  faCog,
  faUser,
  faMinus,
  faArrowsAltH,
  faUpload,
  faCalendarTimes,
  faMobileAlt,
  faEye,
  faEyeSlash,

  // Pro solid
  faChartNetwork,
  faBullseyePointer,

  // Pro regular
  farCheckSquare,
  farSquare,
  farStickyNote,
  farShredder,
  farMinus
)

import '@/filters/currencyFilters'
import './filters/dateTimeFilters'
import './filters/eventTypeFilters'
import './filters/imageFilters'
import './filters/meetingtypeFilters'
import './filters/reservationStatusFilters'
import './filters/settingFilters'
import './filters/stringFilters'
import * as objectHelper from './utils/objectHelper'

Vue.prototype.$objectHelper = objectHelper

import localforge from '@/providers/localforage'
Vue.prototype.$localforge = localforge

// Image cropper
import Cropper from 'cropperjs'
Vue.prototype.$Cropper = Cropper

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import LayoutPageMenu from '@/components/LayoutPageMenu'
const UiComponentDrawer = () => import('@/components/UI/Components/Drawer')
const UiComponentModal = () => import('@/components/UI/Components/Modal')
const UiSearchForm = () => import('@/components/UI/Form/Search')
const UiPageHero = () => import('@/components/UI/PageHero')
const uiPageMenu = () => import('@/components/UI/PageMenu')
const UiSectionHeader = () => import('@/components/UI/SectionHeader')

const BaseFieldTemplate = () => import('@/components/UI/Form/BaseFieldTemplate')
const UiBaseInputField = () => import('@/components/UI/Form/BaseInputField')
const UiBaseDateField = () => import('@/components/UI/Form/BaseDateField')
const UiBaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')
const UiSubmitFormControls = () =>
  import('@/components/UI/Form/SubmitFormControls')
const UiCheckbox = () => import('@/components/UI/Form/Checkbox')
const UiSelect = () => import('@/components/UI/Form/Select')
const UiTextarea = () => import('@/components/UI/Form/Textarea')
const UiTextEditor = () => import('@/components/UI/TextEditor')
const UiLoader = () => import('@/components/UI/Loader')
const UiSvgSetting = () => import('@/components/UI/SvgSetting')

/**
 * UI elements
 */
Vue.component('layout-page-menu', LayoutPageMenu)
Vue.component('ui-page-hero', UiPageHero)
Vue.component('ui-page-menu', uiPageMenu)
Vue.component('ui-section-header', UiSectionHeader)
Vue.component('ui-base-template', BaseFieldTemplate)
Vue.component('ui-base-input-field', UiBaseInputField)
Vue.component('ui-base-date-field', UiBaseDateField)
Vue.component('ui-checkbox', UiCheckbox)
Vue.component('ui-select', UiSelect)
Vue.component('ui-textarea', UiTextarea)
Vue.component('ui-text-editor', UiTextEditor)
Vue.component('ui-loader', UiLoader)
Vue.component('ui-submit-form-controls', UiSubmitFormControls)
Vue.component('ui-base-checkbox', UiBaseCheckbox)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ui-component-modal', UiComponentModal)
Vue.component('ui-component-drawer', UiComponentDrawer)
Vue.component('ui-svg-setting', UiSvgSetting)
Vue.component('ui-search-form', UiSearchForm)

Vue.config.productionTip = false
export const i18n = i18nConfig

let app = null
if (!app) {
  app = new Vue({
    i18n,
    router,
    store,
    Cropper,
    components: {
      // UI elements
      LayoutPageMenu,
      UiPageHero,
      uiPageMenu,
      UiSectionHeader,
      UiBaseInputField,
      UiBaseDateField,
      UiCheckbox,
      UiTextarea,
      UiLoader,
      UiSvgSetting,
      UiSearchForm,
    },
    render: (h) => h(App),

    // Retreive store settings from localStorage
    beforeCreated() {
      localStorage.removeItem('ccmState')
    },
  })
  app.$mount('#app')
}
