import axios from 'axios'
import store from '../store'

export const create = (subscription) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription`,
    JSON.stringify(subscription),
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const remove = (subscription) => {
  return axios.delete(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscription.Id}`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const update = (subscription) => {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscription.Id}`,
    JSON.stringify(subscription),
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {
    async getSubscriptions(channelId, locationId, month, year, packageId, searchTerm) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/subscriptions`,
        {
          params: {
            locationId: locationId,
            month: month,
            year: year,
            packageId: packageId,
            q: searchTerm
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getSubscriptionById(subscriptionId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscriptionId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async addSubscription(subscription) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription`,
        subscription,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateSubscription(subscription) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscription.Id}`,
        subscription,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async stopSubscription(subscriptionId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscriptionId}/stop`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async extendSubscription(subscription) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscription.Id}/extend`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteSubscription(subscriptionId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/subscription/${subscriptionId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
