const serviceToolsRoutes = {
  path: 'services',
  name: 'services',
  components: {
    default: () => import('@/views/PageRoutView'),
  },
  redirect: { name: 'services-widgets' },
  meta: {
    requiresAuth: true,
    isDashboardView: true,
  },
  children: [
    /**
     * Search widgets
     */
    {
      path: '/:channelId/widgets',
      name: 'services-widgets',
      component: () => import('@/views/Services/SearchWidgets'),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/:channelId/widgets/:widgetId',
      name: 'services-widgets-details',
      component: () => import('@/views/Services/SearchWidget'),
      meta: {
        requiresAuth: true,
      },
      props: {
        showStatsProp: true,
        showEmbedProp: false,
        showStylingProp: false,
      },
    },

    {
      path: '/:channelId/widgets/:widgetId',
      name: 'services-widgets-details-edit',
      component: () => import('@/views/Services/SearchWidget'),
      meta: {
        requiresAuth: true,
      },
      props: {
        showStatsProp: false,
        showEmbedProp: false,
        showStylingProp: true,
      },
    },

    // {
    //   path: 'tv',
    //   name: 'application-tv',
    //   component: () =>
    //     import(/* webpackChunkName: "application-tv" */ '@/views/Services/TvApp.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
  ],
}
export default serviceToolsRoutes
