import Vue from "vue";
/**
 * Declare Variable
 */
const categoryStructure = {
  CategoryId: 0,
  CategoryName: "",
  Functionalities: []
};

const state = {
  manageMenu: [],
  selectedCategory: categoryStructure,
  detailPageSubMenu: [],
  submenuLoaded: false,
  selectedMenuItems: [0, 0, 0, 0],
  visiblePageComponent: ""
};

const getters = {
  /**
   * Get menu from state
   */
  getMenu: state => state.manageMenu,
  /**
   * Get seleted menu items from state
   */
  getSelectedMenuItems: state => state.selectedMenuItems,
  /**
   * Get detail page submenu from state
   */
  getDetailPageSubMenu: state => state.detailPageSubMenu,
  /**
   * Get submenu load status
   */
  getSubmenuLoaded: state => state.submenuLoaded,
  /**
   * Get visible page component name
   */
  getVisiblePageComponent: state => state.visibleComponent
};

const mutations = {
  /**
   * Set menu value in state
   */
  setMenu: (state, newState) => {
    // Store selected category in store
    state.manageMenu = newState;
  },

  setSelectedMenuItemIndex: (
    state,
    { menuGroupIndex, selectedMenuItemIndex }
  ) => {
    Vue.set(state.selectedMenuItems, menuGroupIndex, selectedMenuItemIndex);
  },

  /**
   * Set detail page submenu
   */
  setDetailPageSubMenu: (state, newState) => {
    state.detailPageSubMenu = newState;
  },

  /**
   * Get submenu load status
   */
  setSubmenuLoaded: (state, newState) => {
    state.submenuLoaded = newState;
  },
  /**
   * Set visible page component name
   */
  setVisiblePageComponent: (state, newState) => {
    state.visiblePageComponent = newState;
  }
};

const actions = {
  /**
   * Clear selected menu from store
   * @param {Object} context
   */
  clearDetailPageSubMenu(context) {
    context.commit("setDetailPageSubMenu", []);
  }
};

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
