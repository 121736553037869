const newsRoutes = {
  path: 'news',
  name: 'newsOverview',
  components: {
    default: () => import('@/views/PageRoutView')
  },
  redirect: { name: 'news' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/:channelId/news',
      name: 'news-list',
      component: () => import('@/views/News/NewsList'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:channelId/news/:key/edit',
      name: 'news-edit',
      component: () => import('@/views/News/NewsEdit'),
      meta: {
        requiresAuth: true
      }
    },
  ]
}

export default newsRoutes
