import axios from "axios"
import store from "@/store"

export const getPackagesData = (channelId, countryId = 0) => {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/channel/${channelId}/packages`,
    {
      params: {
        countryId: countryId
      },
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const getPackageById = (packageId) => {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/package/${packageId}`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const add = (pckg) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/package`,
    JSON.stringify(pckg),
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const update = (pckg) => {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/package/${pckg.Id}`,
    JSON.stringify(pckg),
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const remove = (packageId) => {
  return axios.delete(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/package/${packageId}`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const activate = (packageId) => {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/package/${packageId}/activate`,
    null,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const deactivate = (packageId) => {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/package/${packageId}/deactivate`,
    null,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {

  }
}
