/**
 * Declare Variable
 */
const state = {
  term: null,

  terms: [],
};

const getters = {
  getTerm: (state) => state.term,

  getTerms: (state) => state.terms,
};

const actions = {};

const mutations = {
  setTerm: (state, data) => {
    state.term = data;
  },

  setTerms: (state, data) => {
    state.terms = data;
  }
};

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
