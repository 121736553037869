import axios from "axios"
import store from "@/store"

export const getCategoriesData = () => {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/functionality/categories`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export const getFunctionalitiesData = () => {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/functionalities`,
    {
      headers: {
        "Content-type": "application/json",
        apiToken: process.env.VUE_APP_API_KEY,
        profileToken: store.getters['profileStore/getProfileToken']
      }
    }
  )
}

export default {
  methods: {

  }
}