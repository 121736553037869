const state = {
  confirmationToken: '',
}

const getters = {
  getConfirmationToken: (state) => state.confirmationToken,
}

const mutations = {
  setConfirmationToken: (state, newValue) => {
    state.confirmationToken = newValue
  },
}

const actions = {}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
