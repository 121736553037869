import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

let getProfileStateToken = null
let getProfileToken = null
let getProfilesToken = null

export default {
  methods: {
    /**
     * Cancel get profile state request
     */
    cancelGetProfileState() {
      if (getProfileStateToken) {
        getProfileStateToken.cancel()
      }
    },

    /**
     * Cancel get profile request
     */
    cancelGetProfile() {
      if (getProfileToken) {
        getProfileToken.cancel()
      }
    },

    /**
     * Cancel get profiles request
     */
    cancelGetProfiles() {
      if (getProfilesToken) {
        getProfilesToken.cancel()
      }
    },

    async getProfiles(
      searchTerm,
      page,
      itemsPerPage,
      channelId,
      channelAuthorizeOnly
    ) {
      // Cancel request and get new token
      this.cancelGetProfiles()
      getProfilesToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/profiles`,
        {
          cancelToken: getProfilesToken.token,
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            channelId: channelId,
            channelAuthorizeOnly: channelAuthorizeOnly
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProfile() {
      // Cancel request and get new token
      this.cancelGetProfile()
      getProfileToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profile`,
        {
          cancelToken: getProfileToken.token,
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProfileById(profileId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profile`,
        {
          params: {
            profileId: profileId
          },
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    /**
     * Get profile state
     */
    async getProfileState() {
      // Cancel request and get new token
      this.cancelGetProfileState()
      getProfileStateToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profile/state`,
        {
          cancelToken: getProfileStateToken.token,
          params: {
            minutes: Vue.options.filters.timeToMinutes(new Date())
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    // async getProfileById(profileId) {
    //   const response = await axios.get(
    //     `${process.env.VUE_APP_API_BASE_URL}/api/v1/profiles/${profileId}`,
    //     {
    //       headers: {
    //         'Content-type': 'application/json',
    //         apiToken: process.env.VUE_APP_API_KEY,
    //         profileToken: store.getters['profileStore/getProfileToken']
    //       }
    //     }
    //   )
    //   return response
    // },

    async activateProfile(profileKey) {
      let criteria = {
        ProfileKey: profileKey
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profiles/activate`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    getProfileScore: () => {
      return axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profiles/score/${store.getters.getProfile.Id
        }`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },

    async getProfileStats() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profiles/stats/${store.getters.getProfile.Id
        }`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProfileMailSettings() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/profiles/mailsettings/${store.getters.getProfile.Id
        }`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteProfile(profileKey) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/profiles?profileKey=${profileKey}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createProfile({
      ChannelId = 0,
      FirstName = '',
      LastName = '',
      Email = '',
      Phone = '',
      Password = '',
      Tags = '',
      LocalePrefix = 'en',
      SubscribeToNewsLetter = 0,
      TermsId = 0,
      HasAgreedToTerms = false,
      LocationId = 0,
      IsBackend = false,
      SendConfirmMail = true,
      RegisterType = 0,
      TypeId = 0,
    } = {}) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/profile`,
        {
          ChannelId: ChannelId,
          LocationId: LocationId,
          FirstName: FirstName,
          LastName: LastName,
          Email: Email,
          Phone: Phone,
          Password: Password,
          LocalePrefix: LocalePrefix,
          IsBackend: IsBackend,
          SendConfirmMail: SendConfirmMail,
          RegisterType: RegisterType,
          TypeId: TypeId,
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },
  }
}
